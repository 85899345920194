import React, { useEffect } from "react"

import { useTranslationContext } from "../../context/TranslationContext"
import NewPassword from "../../components/account/pages/new-password"

const canonicals = {
  ["en-US"]: "/account/new-password",
  ["de-DE"]: "/de/konto/new-password",
}

const NewPasswordPage = () => {
  const { setTranslationContext } = useTranslationContext()

  useEffect(() => {
    setTranslationContext({
      locale: "en-US",
      canonicals: canonicals,
    })
  }, [setTranslationContext])

  return <NewPassword />
}

export default NewPasswordPage
